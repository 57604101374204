import axios from 'axios';
import { useReqResApi } from '../../api/ReqRes';

export const useMakeRequest = () => {

    const { reqResToken, reqError, updateErr, getToken } = useReqResApi();

    const releaseRequest = async ( path : string ) => {
        if ( reqResToken ) {
            try {
                const { data } = await axios.get(
                    `${ process.env.REACT_APP_HOST }/sites/PortalContratos/_api/${ path }`,
                    { headers: reqResToken }
                )
                return data ?? false;
            } catch ( err : any ) {
                updateErr( err );
                return false;
            }
        }
    }

    const makeEmptyRequest = async ( path : string, responseType ?: any ) => {
        if ( reqResToken ) {
            try {
                const { data } = await axios.get(
                    `${ path }`, {
                        headers: reqResToken
                    }
                );
                return data ?? false;
            } catch ( err : any ) {
                updateErr( err );
                return false;
            }
        }
    }

    const makeEmptyRequestBlob = async ( path : string ) => {
        if ( reqResToken ) {
            try {
                const { data } = await axios.get(
                    `${ path }`, {
                        headers: {
                            ...reqResToken,
                            'Access-Control-Allow-Origin': '*'
                        },
                        responseType: 'blob',
                    }
                );
                return data ?? false;
            } catch ( err : any ) {
                updateErr( err );
                return false;
            }
        }
    }

    return { releaseRequest, makeEmptyRequest, makeEmptyRequestBlob, reqError, getToken, reqResToken }

}