// Generated by https://quicktype.io

export interface Process {
    d: D;
}

export interface D {
    results: Result[];
    __next:  string;
}

export interface Result {
    __metadata:                               Metadata;
    FirstUniqueAncestorSecurableObject?:      AttachmentFiles;
    Anexo?:                                   string;
    RoleAssignments?:                         AttachmentFiles;
    AttachmentFiles:                          AttachmentFiles;
    ContentType?:                             AttachmentFiles;
    GetDlpPolicyTip?:                         AttachmentFiles;
    FieldValuesAsHtml?:                       AttachmentFiles;
    FieldValuesAsText?:                       AttachmentFiles;
    FieldValuesForEdit?:                      AttachmentFiles;
    File?:                                    AttachmentFiles;
    Folder?:                                  AttachmentFiles;
    LikedByInformation?:                      AttachmentFiles;
    ParentList?:                              AttachmentFiles;
    Properties?:                              AttachmentFiles;
    Versions?:                                AttachmentFiles;
    FileSystemObjectType?:                    number;
    Id:                                       number | string;
    ServerRedirectedEmbedUri?:                null;
    ServerRedirectedEmbedUrl?:                string;
    ID?:                                      number;
    ContentTypeId?:                           string;
    Title:                                    string;
    Modified:                                 string;
    Created:                                  string;
    AuthorId:                                 number | string;
    EditorId:                                 number | string;
    OData__UIVersionString:                   string;
    Attachments:                              boolean;
    GUID:                                     string;
    ComplianceAssetId?:                       null;
    Procedimientos_x0020_de_x0020_se:         ProcedimientosX0020DeX0020SE | null;
    Regimen_x0020_de_x0020_Contratac:         RegimenX0020DeX0020Contratac | null;
    Presupuesto:                              number | null;
    Tipo_x0020_de_x0020_Moneda:               TipoX0020DeX0020Moneda | null;
    Pais_x0020_de_x0020_ejecuci_x00fId:       number | null;
    Ciudad_x0020_de_x0020_ejecuci_x0Id:       number | null;
    Objeto_x003a_:                            string;
    Requisitos_x0020_T_x00e9_cnicos_:         null | string;
    Fecha_x0020_del_x0020_acto_x0020:         null | string;
    Gestor_x0020_de_x0020_AbastecimiId:       number | string;
    Gestor_x0020_de_x0020_AbastecimiStringId: string;
    Nombre_x0020_del_x0020_FuncionarId:       number | null;
    Nombre_x0020_del_x0020_FuncionarStringId: null | string;
    Dependencia_x0020_del_x0020_Func:         null | string;
    Tel_x00e9_fono_x0028_s_x0029__x0:         null | string;
    Correo_x0020_Electr_x00f3_nico_x:         null | string;
    Pais_x0020_Entrega_x0020_de_x002Id:       number | null;
    Ciudad_x0020_Entrega_x0020_de_x0Id:       number | null;
    Direcci_x00f3_n_x0020_Entrega_x0:         null | string;
    Fecha_x0020_Inicial_x0020_Entreg:         null | string;
    Fecha_x0020_Final_x0020_Entrega_:         null | string;
    Horario_x0020_de_x0020_Atenci_x0:         HorarioX0020DeX0020AtenciX0 | null;
    Otro_x0020_horario:                       null | string;
    Urna:                                     null | string;
    Fecha_x0020__x0028_acto_x0020_de:         null | string;
    Fecha_x0020_Inicial_x0020_Public:         null | string;
    Fecha_x0020_Final_x0020_Publicac:         null | string;
    Fecha_x0020_inicial_x0020_observ:         null | string;
    Fecha_x0020_final_x0020_observac:         null | string;
    N_x00fa_mero_x0020_de_x0020_iden:         null | string;
    Nombre_x0020_o_x0020_raz_x00f3_n:         null | string;
    Valor_x0020_de_x0020_Contrato:            number | null;
    N_x00fa_mero_x0020_del_x0020_Con:         null | string;
    Estado_x0020_del_x0020_Proceso:           EstadoX0020DelX0020Proceso;
    AnexosId:                                 number | null;
    Si_x0020_usted_x0020_es_x0020_un:         null | string;
    Si_x0020_usted_x0020_es_x0020_un0:        null | string;
}

export interface AttachmentFiles {
    __deferred: Deferred;
}

export interface Deferred {
    uri: string;
}

export enum EstadoX0020DelX0020Proceso {
    Adjudicado = "ADJUDICADO",
    Cancelado = "CANCELADO",
    EnTramite = "EN TRAMITE",
    Fallido = "FALLIDO",
}

export enum HorarioX0020DeX0020AtenciX0 {
    De700AmA1100MYDe100PmA330Pm = "DE: 7:00 AM A 11:00 M Y DE 1:00 PM A 3:30 PM",
    De800AmA1200MYDe200PmA430Pm = "DE: 8:00 AM A 12:00 M Y DE 2:00 PM A 4:30 PM",
    De830AmA1230MYDe230PmA500Pm = "DE: 8:30 AM A 12:30 M Y DE 2:30 PM A 5:00 PM",
    Otro = "Otro",
}

export enum ProcedimientosX0020DeX0020SE {
    ConcursoAbierto = "CONCURSO ABIERTO",
    InteligenciaDeMercado = "INTELIGENCIA DE MERCADO",
    Prepliegos = "PREPLIEGOS",
}

export enum RegimenX0020DeX0020Contratac {
    ManualDeContratacion = "MANUAL DE CONTRATACION",
}

export enum TipoX0020DeX0020Moneda {
    DólarAmericano = "DÓLAR AMERICANO",
    PesosColombianos = "PESOS COLOMBIANOS",
}

export interface Metadata {
    id:   string;
    uri?:  string;
    etag?: Etag;
    type?: Type;
}

export enum Etag {
    The1 = "\"1\"",
}

export enum Type {
    SPDataProcesosListItem = "SP.Data.ProcesosListItem",
}
