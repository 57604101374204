import { IconButton, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC } from "react";

interface Props {
    trigger : () => void
}

export const Noattachment: FC<Props> = ({ trigger }) => {
    return (
        <div className = "Modalattachment">
            <Typography>
                Lamentamos los incovenientes
            </Typography>
            <span className = "ModalAttSubtitle">
                No se encontraron los adjuntos
            </span>
            <IconButton onClick = { trigger }>
                <ArrowBackIcon/>
            </IconButton>
        </div>
    )
}
