import MUIDataTable from 'mui-datatables'
import { FC } from 'react';
import { UseHeightContainer } from './UseDimensions';

interface Options {
    sort?: boolean, filter?: boolean; display?: boolean;
    customBodyRender?: ( data : any, tableMeta : any ) => any
}

export const MUISPOptions = {
    textLabels: {
        body: {
            noMatch: "Lo sentimos, no se encontraron registros coincidentes",
            toolTip: "Ordenar",
            columnHeaderTooltip: ( column : any ) => `Ordenar por ${ column.label }`,
        },
        pagination: {
            next: "Sig. Pag.",
            previous: "Ant. Pag",
            rowsPerPage: "Filas por pag.:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Columnas",
            filterTable: "Filtrar Tabla",
        },
        filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "REINICIAR",
        },
        viewColumns: {
            title: "Mostar Columnas",
            titleAria: "Mostar/Ocultar Columnas",
        },
        selectedRows: {
            text: "Fila(s) seleccionadas",
            delete: "Eliminar",
            deleteAria: "Eliminar Filas Seleccionadas",
        }
    }
}

export interface RowMeta {
    dataIndex   : number;
    rowIndex    : number;
}

export interface HeadersLabels {
    label            : string;
    options         ?: Options;
    name             : string;
    selectable      ?: any;
    noEdit          ?: boolean;
    onlyEdit        ?: boolean;
    params          ?: any;
    init            ?: any;
    value           ?: any;
}

interface MUIProps {
    title                : string;
    data                 : any[];
    columns              : HeadersLabels[];
    heightPorcentage    ?: 'normal' | 'small';
    aditionalOptions    ?: any;
    handleRowClick      ?: ( rowData : string[], rowMeta: RowMeta ) => void
}

// const TABLESIZES = {
//     normal : 0.85,
//     small  : 0.65
// }

export const UseMuiTable: FC<MUIProps> = (
    { title, data, columns, aditionalOptions, handleRowClick }
) => {

    const { height } = UseHeightContainer(
        { container : 'mainContainer', padding : 270 }
    );

    const defineActions = () => {
        let tableActions: any = {
            download: false,
            fixedHeader: true,
        }
        if ( handleRowClick ) {
            tableActions = { ...tableActions, onRowClick: handleRowClick  }
        }
        return tableActions;
    }

    const muiActions = defineActions();
    
    return (
        <div className = "ContainerTable">
            <MUIDataTable
                data = { data }
                columns = {
                    columns.map(({ name, label, options }) => {
                        return {
                            name,
                            label,
                            options: {
                                ...options,
                                customRowRender( value : any ) {
                                    return value
                                },
                                customHeadLabelRender({ label }) {
                                    return (
                                        <div
                                            style = {{
                                                fontWeight: 'bold',
                                                opacity: '0.7'
                                            }}
                                        >
                                            { label }
                                        </div>
                                    )
                                },
                            }
                        }
                    })
                }
                options = {{
                    ...muiActions,
                    ...MUISPOptions,
                    ...aditionalOptions,
                    responsive: 'standard',
                    fixedHeader: true,
                    tableBodyHeight: `${ height }px`,
                    resizableColumns: false,
                    download: true,
                    print: false,
                    selectableRowsOnClick: false,
                    draggableColumns: {
                        enabled: true,
                    }
                }}
                title  = { title }
            />
        </div>
    )
}
