import { LinearProgress, Box } from "@mui/material";
import logo from '../../assets/img/Ecopetrol_logo.png';

export const Loading = () => (
    <div className = "ModalLoading">
        <img src = { logo } alt = "loading" />
        <Box sx = {{ width: '100%' }} >
            <LinearProgress />
        </Box>
    </div>
)
