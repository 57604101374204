import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ShareProvider } from "../Context/ShareContext";
import { Home } from "./pages/Home";
import { DetailContract } from './pages/DetailContract';
import { Attachments } from './pages/Attachments';

export const Navigation = () => (
  <ShareProvider>
    <BrowserRouter>
      <Routes>
        <Route path = "/" element = { <Home/> }/>
        <Route path = "/home" element = { <Home/> }/>
        <Route path = "/detail" element = { <DetailContract/> }/>
        <Route path = "/detail/:id" element = { <Attachments/> }/>
      </Routes>
    </BrowserRouter>
  </ShareProvider>
)