import { useEffect, useState, useContext, useRef } from 'react';
import { headers, customRenderDate } from '../data/MUITableheaders';
import { MainContainer } from "../templates/MainContainer"
import { Result, Process } from '../../Interfaces/Process';
import { ProcessHeaders } from '../../Interfaces/ProcessHeaders';
import { fieldsRequest, ShareContext } from '../../Context/ShareContext';
import { useNavigate } from 'react-router-dom';
// import { SearchComponent } from '../templates/SearchComponent';
import { useMakeRequest } from '../data/MakeRequest';
import { UseDialog } from '../../hooks/UseDialog';
import { Loading } from '../Atoms/Loading';
import { UseMuiTable, RowMeta } from '../../hooks/UseMuiTable';

export const orderOptions = ( data : Result[] ) => {
    const thirdItems : Result[] = [];
    const info: any = data.filter(
        ({ Fecha_x0020_del_x0020_acto_x0020, ...rest }) => {
            if ( Fecha_x0020_del_x0020_acto_x0020 !== null ) {
                return true;
            }
            thirdItems.push({
                ...rest, Fecha_x0020_del_x0020_acto_x0020
            });
            return false;
        }
    )
    return [
        ...info.sort(( a : any, b : any ) => {
            const info1 = new Date( a.Fecha_x0020_del_x0020_acto_x0020 );
            const info2 = new Date( b.Fecha_x0020_del_x0020_acto_x0020 );
            if ( info1.getTime() < info2.getTime() ) {
                return 1;
            }
            if ( info1.getTime() > info2.getTime() ) {
                return -1;
            }
            return 0;
        }),
        ...thirdItems
    ]
}

export const Home = () => {
    
    const requestTotalRef = useRef( 20000 );
    const historyDataRef = useRef<Result[]>([]);
    const [ info, setInfo ] = useState<Result[] | []>([]);
    const { selectItem, shareState, updateHeads } = useContext( ShareContext );
    const navigate = useNavigate();
    const { releaseRequest, reqResToken } = useMakeRequest();

    const requestHeaders = async () => {
        const { d }: ProcessHeaders = await releaseRequest(
            `${ process.env.REACT_APP_HOME_HEADERS }`
        );
        updateHeads({
            tableHeads: [
                ...headers.filter(
                    ({ label }) => d.results.find (
                        ({ Title }) => Title === label 
                    )
                ),
                headers[ headers.length - 1 ]
            ],
            detailHeads: d.results.map(({ Title }) => ({
                Title
            }))
        });
    }

    const requestInfo = async () => {

        const { d } : Process = await releaseRequest(
            `${ process.env.REACT_APP_HOME_INFO }${ fieldsRequest }$orderby=ID%20desc&$top=${ requestTotalRef.current }`
        );
        if ( d ) {
            historyDataRef.current = orderOptions( d.results );
            setInfo( historyDataRef.current );
        }
    }
    
    const buildObject = ( value ?: string ) => {
        if ( value ) {
            value = value.replace( '&#160;', '' );
            return value.includes( '</div>' ) 
                ? value.replace(/(<([^>]+)>)/ig, '') : value
        }
        return ''
    }

    const handleRowClick = ( rowData: string[], rowMeta: RowMeta ) => {
        const itemSelected =  historyDataRef.current
            .find( item => item.Id === rowData[ rowData.length - 1  ])
        if ( itemSelected ) {
            const { Anexo, AnexosId } = itemSelected;
            selectItem({ ...itemSelected, AnexosId : Anexo ? parseInt( Anexo ) : AnexosId });
            navigate( '/detail' )
        }
    }

    useEffect(() => {
        if ( reqResToken ) {
            requestHeaders();
            requestInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ reqResToken ]);

    return (
        <MainContainer>
            <div className = "shareTable">
                {
                    info[0] && shareState.tableHeads[0] ? (
                        <UseMuiTable
                            title  = "Procesos"
                            data = {
                                info.map( item => ({
                                    Title: buildObject( item.Title ),
                                    Objeto_x003a_: buildObject( item.Objeto_x003a_ ),
                                    Presupuesto: item.Presupuesto || '',
                                    Fecha_x0020_del_x0020_acto_x0020: item.Fecha_x0020_del_x0020_acto_x0020
                                        ? customRenderDate( item.Fecha_x0020_del_x0020_acto_x0020 ) : '',
                                    Fecha_x0020_Final_x0020_Entrega_: item.Fecha_x0020_Final_x0020_Entrega_
                                        ? customRenderDate(item.Fecha_x0020_Final_x0020_Entrega_) : '',
                                    Estado_x0020_del_x0020_Proceso: item.Estado_x0020_del_x0020_Proceso || '',
                                    Id: item.Id
                                }))
                            }
                            handleRowClick = { handleRowClick }
                            columns = {
                                shareState.tableHeads.map(({ label, name, options }) => ({
                                    label, name, options: options || {
                                        filter: true, sort: true
                                    }
                                }))
                            }
                            aditionalOptions = {{
                                selectableRows: 'none',
                            }}
                            heightPorcentage = "normal"
                        />
                    ) : (
                        <UseDialog
                            title = ""
                            handleClose = { () => '' }
                            open = { true }
                            component = { <Loading /> }
                        />
                    )
                }
            </div>
        </MainContainer>
    )

}
