import Logo from '../../assets/img/Ecopetrol_logo.png'

export const MainHeader = () => {
  return (
    <div id = "MainHeader">
        <div id = "disclaimer">
            <span>Bienvenido al portal de procesos de contratación de Ecopetrol</span>    
        </div>
        <div id = "logo">
            <img src = { Logo } alt = "logo" />
            <span>
              <strong>Proceso de contratación</strong>
            </span>
        </div>
    </div>
  )
}
