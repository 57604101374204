import { Dialog, DialogContent, DialogTitle } from "@mui/material";

interface Props {
    open: boolean;
    title: string;
    handleClose: () => void;
    component: any
}

export const UseDialog = ({ open, title, handleClose, component } : Props) => (
    <Dialog
        open = { open }
        onClose = { handleClose }
        aria-labelledby = "alert-dialog-title"
        aria-describedby = "alert-dialog-description"
    >
        <DialogTitle id = "ModalContentDialog">
            { title }
        </DialogTitle>
        <DialogContent>
            { component }
        </DialogContent>
    </Dialog>
)
