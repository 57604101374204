import { createContext, useReducer } from 'react';
import { Result, EstadoX0020DelX0020Proceso } from '../Interfaces/Process';
import { shareReducer } from './ShareReducer';
import { HeadsInterface } from '../components/data/MUITableheaders';

interface HeadsTitle {
    Title: string
}

interface PropsHeads {
    tableHeads: HeadsInterface[] | [],
    detailHeads: HeadsTitle[] | []
}

export interface ShareState {
    selected: Result,
    tableHeads: HeadsInterface[] | [],
    detailHeads: HeadsTitle[] | []
}

export const fieldsRequest = "(%27Procesos%27)/Items?=Title,ID,Objeto_x003a_,Presupuesto,Fecha_x0020_del_x0020_acto_x0020,Estado_x0020_del_x0020_Proceso,Fecha_x0020_Final_x0020_Entrega_&"

const validateStorage = ( path: string ): any => {
    const temp = localStorage.getItem( path );
    return temp && JSON.parse( temp )
}

export const shareInit: ShareState = {
    selected: validateStorage( 'selectedTable' ) || {
        __metadata: {
            id: ''
        },
        AttachmentFiles: {
            __deferred: { uri: '' }
        },
        Id: 0,
        Title: '',
        Modified: '',
        Created: '',
        AuthorId: '',
        EditorId: '',
        OData__UIVersionString: '',
        Attachments: false,
        GUID: '',
        Nombre_x0020_del_x0020_FuncionarStringId: '',
        Procedimientos_x0020_de_x0020_se: null,
        Regimen_x0020_de_x0020_Contratac: null,
        Presupuesto: null,
        Tipo_x0020_de_x0020_Moneda: null,
        Pais_x0020_de_x0020_ejecuci_x00fId: null,
        Ciudad_x0020_de_x0020_ejecuci_x0Id: null,
        Objeto_x003a_: '',
        Requisitos_x0020_T_x00e9_cnicos_: null,
        Fecha_x0020_del_x0020_acto_x0020: null,
        Gestor_x0020_de_x0020_AbastecimiId: '',
        Gestor_x0020_de_x0020_AbastecimiStringId: '',
        Nombre_x0020_del_x0020_FuncionarId: null,
        Dependencia_x0020_del_x0020_Func: '',
        Tel_x00e9_fono_x0028_s_x0029__x0: '',
        Correo_x0020_Electr_x00f3_nico_x: '',
        Pais_x0020_Entrega_x0020_de_x002Id: null,
        Ciudad_x0020_Entrega_x0020_de_x0Id: null,
        Direcci_x00f3_n_x0020_Entrega_x0: '',
        Fecha_x0020_Inicial_x0020_Entreg: '',
        Fecha_x0020_Final_x0020_Entrega_: '',
        Horario_x0020_de_x0020_Atenci_x0: null,
        Otro_x0020_horario: '',
        Urna: '',
        Fecha_x0020__x0028_acto_x0020_de: '',
        Fecha_x0020_Inicial_x0020_Public: '',
        Fecha_x0020_Final_x0020_Publicac: '',
        Fecha_x0020_inicial_x0020_observ: '',
        Fecha_x0020_final_x0020_observac: '',
        N_x00fa_mero_x0020_de_x0020_iden: '',
        Nombre_x0020_o_x0020_raz_x00f3_n: '',
        Valor_x0020_de_x0020_Contrato: null,
        N_x00fa_mero_x0020_del_x0020_Con: '',
        Estado_x0020_del_x0020_Proceso: EstadoX0020DelX0020Proceso.Adjudicado,
        AnexosId: null,
        Si_x0020_usted_x0020_es_x0020_un: '',
        Si_x0020_usted_x0020_es_x0020_un0: '',
    },
    tableHeads: validateStorage( 'detailHeads' ) || [],
    detailHeads: []
}

export interface ShareContextProps {
    shareState: ShareState,
    selectItem: ( props: Result ) => void,
    updateHeads: ( props: PropsHeads ) => void
}

export const ShareContext = createContext( {} as ShareContextProps );

export const ShareProvider = ({ children } : any) => {
    
    const [ shareState, dispatch ] = useReducer( shareReducer, shareInit );

    const selectItem = ( value: Result ) => {
        localStorage.setItem( 'selectedTable', JSON.stringify( value ));
        dispatch({ type: 'selectItem', payload: value })
    }

    const updateHeads = ( value: PropsHeads ) => {
        localStorage.setItem( 'detailHeads', JSON.stringify( value.detailHeads ))
        dispatch({ type: 'updateHeads', payload: value });
    }

    return (
        <ShareContext.Provider
            value = {{
                ...shareState,
                shareState,
                selectItem,
                updateHeads
            }}
        >
            { children }
        </ShareContext.Provider>
    )
}