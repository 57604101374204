import { ShareState } from './ShareContext';

type ShareActions = {
    type: 'selectItem' | 'updateHeads',
    payload: any
}

export const shareReducer = (
    state: ShareState, action: ShareActions
) => {
    switch( action.type ) {
        case 'selectItem':
            return {
                ...state,
                selected: action.payload
            }
        case 'updateHeads':
            return {
                ...state,
                tableHeads: action.payload.tableHeads,
                detailHeads: action.payload.detailHeads
            }
        default:
            return { ...state }
    }
}
