import axios from 'axios';
import { useEffect, useState } from 'react';

export const tokenApp = process.env.REACT_APP_TOKEN
export const reqRes = axios.create({
    baseURL: `${ process.env.REACT_APP_HOST }/sites/PortalContratos/_api/`,
    headers: {
        Authorization: "Bearer " + tokenApp,
        Accept: "application/json;odata=verbose"
    }
});

export const resReqToken = axios.create({
    baseURL: `${ process.env.REACT_APP_TOKEN_URL }`,
    headers: {
        client_id: `66260013-b094-4528-b39f-3531e082aad3@a4305987-cf78-4f93-9d64-bf18af65397b`,
        client_secret: `gVfiHu58qdE6OCkDoVdAIh+OHdM4Mm+Jm6yIX/ER9Cc=`,
        grant_type: 'client_credentials',
        resource: `00000003-0000-0ff1-ce00-000000000000/ecopetrol.sharepoint.com@a4305987-cf78-4f93-9d64-bf18af65397b`,
        'ocp-apim-subscription-key': `c1855b3cded44f9cbea5451ac5e936fa`
    }
})

export const useReqResApi = () => {
    
    const [ reqResToken, setToken ] = useState<any | undefined>();
    const [ reqError, setError ] = useState();

    const getToken = async () => {
        try {
            const { data } = await resReqToken.get('');
            setToken( data.headers );
        } catch ( err : any ) {
            setError( err.response.data );
        }
    }

    const updateErr = ( err : any ) => setError( err );

    useEffect(() => {
        getToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return { reqResToken, reqError, getToken, updateErr }
}

//https://ecopetrol.sharepoint.com/sites/PortalContratos/Anexos de Procesos/Forms/AllItems.aspx