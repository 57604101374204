import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface Props {
    minWidth?: number;
    headers: string[];
    body: () => any[];
}

export const UseTable = ({ minWidth, headers, body } : Props ) => {
    return (
        <TableContainer component = { Paper }>
            <Table
                sx = {{ minWidth: minWidth || 650 }}
                aria-label = "simple table"
            >
                <TableHead>
                    <TableRow>
                        {
                            headers.map(( title, index ) => (
                                <TableCell align = "left" key = { index }>
                                    { title }
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { body() }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
