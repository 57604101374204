import dayjs from 'dayjs';
import { customRenderDate } from '../data/MUITableheaders';
import { Link } from 'react-router-dom';

export interface PropsDetails {
    key: string;
    title: string;
    render: ( props: any ) => any;
}

interface Attachment {
    Title: string; Attach ?: number;
}

const stringOut = ( value ?: string ) => {
    if ( value ) {
        value = value.replace( '&#160;', '' );
        return value.includes( '</div>' ) 
            ? value.replace(/(<([^>]+)>)/ig, '') : value
    }
    return ''
}

const numberOut = ( value ?: number ) => value || '';

export const formatDate = ( value ?: string ) => {
    if ( value ) {
        const temp = new Date( value );
        return `${ temp.getDate() }-${ temp.getMonth() }-${ temp.getFullYear() } - ${ dayjs( temp ).format( 'hh:mm:ss' ) }`
    }
    return '';
}

const jsxOut = ( value ?: string ) => {
    if ( value ) {
        value = value.replace( '&#160;', '' );
        return value.includes( '</div>' ) 
            ? value.replace(/(<([^>]+)>)/ig, '') : value
    }
    return ''
};

const formatMoney = ( value ?: number ) => {
    if ( value ) {
        const formatType = Intl.NumberFormat( 'es-CO' );
        return `$${ formatType.format( value ) }`;
    }
    return value;
}

const defineAttach = ({ Attach } : Attachment ) => {
    return Attach ? (
        <Link to = { `/detail/${ Attach }` }>
            { `${ Attach }` }
        </Link> 
    ) : <span />
}

export const HeadersDetail: PropsDetails[] = [
    {
        key: 'Title',
        title: 'Número de Proceso',
        render: stringOut
    },
    {
        key: 'Procedimientos_x0020_de_x0020_se',
        title: 'Procedimientos de selección',
        render: stringOut
    },
    {
        key: 'Regimen_x0020_de_x0020_Contratac',
        title: 'Regimen de Contratación',
        render: stringOut
    },
    {
        key: 'Presupuesto',
        title: 'Presupuesto',
        render: formatMoney
    },
    {
        key: 'Tipo_x0020_de_x0020_Moneda',
        title: 'Tipo de Moneda',
        render: stringOut
    },
    {
        key: 'Pais_x0020_de_x0020_ejecuci_x00fId',
        title: 'Pais de ejecución del contrato',
        render: numberOut
    },
    {
        key: 'Ciudad_x0020_de_x0020_ejecuci_x0Id',
        title: 'Ciudad de ejecución del contrato',
        render: numberOut
    },
    {
        key: 'Objeto_x003a_',
        title: 'Objeto',
        render: jsxOut
    },
    {
        key: 'Requisitos_x0020_T_x00e9_cnicos_',
        title: 'Requisitos Técnicos Generales: (Máximo 500 caracteres)',
        render: stringOut
    },
    {
        key: 'Fecha_x0020_del_x0020_acto_x0020',
        title: 'Fecha del acto de apertura del proceso',
        render: customRenderDate
    },
    {
        key: 'Nombre_x0020_del_x0020_FuncionarId',
        title: 'Nombre del Funcionario Autorizado',
        render: numberOut
    },
    {
        key: 'Dependencia_x0020_del_x0020_Func',
        title: 'Dependencia del Funcionario Autorizado',
        render: stringOut
    },
    {
        key: 'Tel_x00e9_fono_x0028_s_x0029__x0',
        title: 'Teléfono(s) donde se obtiene información del Proceso',
        render: stringOut
    },
    {
        key: 'Correo_x0020_Electr_x00f3_nico_x',
        title: 'Correo Electrónico del Proceso',
        render: stringOut
    },
    {
        key: 'Pais_x0020_Entrega_x0020_de_x002Id',
        title: 'Pais Entrega de Propuestas',
        render: numberOut
    },
    {
        key: 'Ciudad_x0020_Entrega_x0020_de_x0Id',
        title: 'Ciudad Entrega de Propuesta',
        render: numberOut
    },
    {
        key: 'Direcci_x00f3_n_x0020_Entrega_x0',
        title: 'Dirección Entrega de Propuestas',
        render: stringOut
    },
    {
        key: 'Fecha_x0020_Inicial_x0020_Entreg',
        title: 'Fecha Inicial Entrega de Propuestas',
        render: customRenderDate
    },
    {
        key: 'Fecha_x0020_Final_x0020_Entrega_',
        title: 'Fecha Final Entrega de Propuestas',
        render: customRenderDate
    },
    {
        key: 'Horario_x0020_de_x0020_Atenci_x0',
        title: 'Horario de Atención Centro de Recepción de Ofertas',
        render: stringOut
    },
    {
        key: 'Otro_x0020_horario',
        title: 'Otro horario',
        render: stringOut
    },
    {
        key: 'Urna',
        title: 'Urna',
        render: stringOut
    },
    {
        key: 'Fecha_x0020__x0028_acto_x0020_de',
        title: 'Fecha (acto de asignación, fallido o cancelado)',
        render: customRenderDate
    },
    {
        key: 'Fecha_x0020_Inicial_x0020_Public',
        title: 'Fecha Inicial Publicación Informe de Evaluación',
        render: customRenderDate
    },
    {
        key: 'Fecha_x0020_Final_x0020_Publicac',
        title: 'Fecha Final Publicación Informe de Evaluación',
        render: customRenderDate
    },
    {
        key: 'Fecha_x0020_inicial_x0020_observ',
        title: 'Fecha inicial observaciones al informe de evaluación',
        render: customRenderDate
    },
    {
        key: 'Fecha_x0020_final_x0020_observac',
        title: 'Fecha final observaciones al informe de evaluación',
        render: customRenderDate
    },
    {
        key: 'N_x00fa_mero_x0020_de_x0020_iden',
        title: 'Número de identificación del proponente adjudicado',
        render: stringOut
    },
    {
        key: 'Nombre_x0020_o_x0020_raz_x00f3_n',
        title: 'Nombre o razón social del proponente adjudicado',
        render: stringOut
    },
    {
        key: 'Valor_x0020_de_x0020_Contrato',
        title: 'Valor de Contrato',
        render: numberOut
    },
    {
        key: 'N_x00fa_mero_x0020_del_x0020_Con',
        title: 'Número del Contrato',
        render: numberOut
    },
    {
        key: 'Estado_x0020_del_x0020_Proceso',
        title: 'Estado del Proceso',
        render: numberOut
    },
    {
        key: 'AnexosId',
        title: 'Anexos',
        render: ( value ?: any ) => {
            return value ? defineAttach( value ) : <span />
        }
    },
    {
        key: 'Si_x0020_usted_x0020_es_x0020_un',
        title: 'Si usted es un proveedor no registrado realice el auto registro en SIPROE aquí',
        render: ( value ?: string ) => value ? (
            <a
                href = "https://siproe.ecopetrol.com.co/proveedor"
                target = "_blank"
                rel = "noreferrer"
            >
                https://siproe.ecopetrol.com.co/proveedor
            </a>
        ) : <span />
    },
    {
        key: 'Si_x0020_usted_x0020_es_x0020_un0',
        title: 'Si usted es un proveedor registrado en SIPROE ingrese al proceso con su usuario y contraseña por la siguiente ruta y después diríjase a Procesos de Contratación / Licitaciones y subastas / N° proceso',
        render: ( value ?: string ) => value ? (
            <a
                href = "https://siproe.ecopetrol.com.co/slc_selfreg"
                target = "_blank"
                rel = "noreferrer"
            >
                https://siproe.ecopetrol.com.co/slc_selfreg
            </a>
        ) : <span />
    }
];
