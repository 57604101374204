import { useEffect, useState } from 'react';
import { MainContainer } from '../templates/MainContainer';
import { useMakeRequest } from '../data/MakeRequest';
import { Content, D, FilesArr, ArrResults } from '../../Interfaces/AttachFiles';
import { useParams, useNavigate } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { UseTable } from '../../hooks/UseTable';
import { TableRow, TableCell, IconButton } from '@mui/material';
import { UseDialog } from '../../hooks/UseDialog';
import { Loading } from '../Atoms/Loading';
import { Noattachment } from '../Atoms/Noattachment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
interface FileProps {
    fileUrl : string;
    length  : number;
    files   : ArrResults[];
}

export const Attachments = () => {

    const [{ url, status }, setProps ] = useState({
        url: '', status: 0
    });
    const [{ fileUrl, length, files }, setFiles ] = useState<FileProps>({
        fileUrl: '', length: 0, files: []
    })
    const { id } = useParams();
    const { releaseRequest, makeEmptyRequest, makeEmptyRequestBlob, reqResToken } = useMakeRequest();
    const [ loading, setloading ] = useState({
        content: <Loading/>, open: false
    });
    const params = useParams();
    const navigate = useNavigate();

    const backToHome = () => navigate( -1 );

    const requestURI = async () => {
        const out: any = await releaseRequest( `/lists/getbytitle('Anexos de Procesos')/Items(${ id })` );
        if ( out && out.d ) {
            const info: D = out.d;
            setloading({ open: false, content: <></> });
            setProps({ status : 1, url: info.Folder.__deferred.uri });
        } else {
            setloading({ open: true, content: <Noattachment trigger = { backToHome }/> });
            setProps({
                url: '',
                status: -1
            })
        }
    }

    const requestFolder = async () => {
        const out : any = await makeEmptyRequest( url );
        if ( out.d ) {
            const { Files } : Content = out.d;
            setFiles({
                fileUrl: Files.__deferred.uri,
                length, files
            })
        } else {
            setloading({ open: true, content: <Noattachment trigger = { backToHome }/> });
        }
    }

    const requestFiles = async () => {
        const out : any = await makeEmptyRequest( fileUrl );
        if ( out.d ) {
            const { results }: FilesArr = out.d;
            results.length > 0
                ? setFiles({ fileUrl, length: results.length, files: results })
                : setloading({ open: true, content: <Noattachment trigger = { backToHome }/> });
        } else {
            setloading({ open: true, content: <Noattachment trigger = { backToHome }/> });
        }
    }

    const handleFile = async ( url : string, name : string ) => {
        try {
            const response = await makeEmptyRequestBlob( `${ url }/$value` );
            const href = URL.createObjectURL( response );
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute( 'download', `${ name }` ); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        
            URL.revokeObjectURL(href);
        } catch ( err : any ) {
            console.log( err.message )
        }
    }

    const buildBody = () => files.map(({ UniqueId, Name, __metadata }, index ) => (
        <TableRow
            key = { `${ UniqueId } - ${ index }` }
            sx = {{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component = "th" scope = "row" align = 'left'>
                <AttachFileIcon/>
            </TableCell>
            <TableCell component = "th" scope = "row" align = 'center'>
                <span
                    className = "fileContent"
                    onClick = { () => handleFile( `${ __metadata.uri }`, Name ) }
                >
                    { Name }
                </span>
            </TableCell>
        </TableRow>
    ))

    useEffect(() => {
        reqResToken && requestURI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ reqResToken ]);

    useEffect(() => {
        status === 1 && requestFolder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ status ]);

    useEffect(() => {
        fileUrl !== '' && requestFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ fileUrl ]);

    return (
        <MainContainer>
            <div className = "AttachmentContainer">
                <div className = "DetailHeader">
                    <div className = 'DetailButtons'>
                        <IconButton onClick = { backToHome }>
                            <ArrowBackIcon/>
                        </IconButton>
                    </div>
                    <span>
                        Procesos: { params.id }
                    </span>
                </div>
                {
                    files[0] && (
                        <>
                            <span style = {{ padding: 10 }}>{ `Cuenta = ${ length }` }</span>
                            <UseTable
                                minWidth = { 400 }
                                headers = {[ 'Tipo', 'Nombre' ]}
                                body = { buildBody }
                            />
                        </>
                    )
                }
                <UseDialog
                    title = ""
                    handleClose = { backToHome }
                    open = { loading.open }
                    component = { loading.content }
                />
            </div>
        </MainContainer>
    )
}
