import { ShareContext } from '../../Context/ShareContext';
import { useContext } from 'react';
import { MainContainer } from '../templates/MainContainer';
import { Result } from '../../Interfaces/Process';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { HeadersDetail } from '../Atoms/HeadersDetail';
import { UseHeightContainer } from '../../hooks/UseDimensions';

export const DetailContract = () => {
    
    const { shareState } = useContext( ShareContext );
    const { height } = UseHeightContainer({ container : 'mainContainer', padding : 0 });

    return (
        <MainContainer>
            <div className = "DetailContainer">
                <div className = "DetailHeader">
                    <div className = 'DetailButtons'>
                        <Link to = "/">
                            <ArrowBackIcon/>
                        </Link>
                    </div>
                    <span>
                        Procesos: { shareState.selected.Title }
                    </span>
                </div>
                <div className = "DetailBody" style = {{ minHeight: height - 164 }}>
                    {
                        HeadersDetail.map(({ key, title, render }) => {
                            const temp = shareState.selected[ key as keyof Result ]?.toString();
                            return (
                                <div className = 'DetailBodyContent' key = { key }>
                                    <div className = "DetailHeader">
                                        { title }
                                    </div>
                                    <div className = "DetailDesc">
                                        {
                                            render(
                                                key === 'AnexosId' ? { 
                                                    Title: shareState.selected.Title, Attach: temp
                                                } : temp
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </MainContainer>
    )
}
