import { MainHeader } from "./MainHeader";

export const MainContainer = ({ children } : any ) => (
    <div className = "mainContainer" id = "mainContainer">
        <MainHeader />
        <div className = "MainStyled">
            { children }
        </div>
    </div>
)
