import parse from 'html-react-parser';
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';

export interface MUIHeadProps {
    index:               number;
    name:                string;
    label:               string;
    display:             string;
    empty:               boolean;
    filter:              boolean;
    sort:                boolean;
    print:               boolean;
    searchable:          boolean;
    download:            boolean;
    viewColumns:         boolean;
    sortCompare:         null;
    sortThirdClickReset: boolean;
    sortDescFirst:       boolean;
}

interface OptionsColumns {
    filter                  ?: boolean;
    sort                    ?: boolean;
    display                 ?: boolean;
    customBodyRender        ?: any;
    customBodyRenderLite    ?: any;
    customHeadLabelRender   ?: any;
    customHeadRender        ?: ( props : MUIHeadProps ) => any;
    customRowRender         ?: ( props : MUIHeadProps ) => any;
    setCellHeaderProps      ?: ( value : any ) =>  any
    setCellProps            ?: any;
    filterList              ?: any
}

export interface HeadsInterface {
    label              : string;
    labelValidator    ?: string;
    name               : string;
    options           ?: OptionsColumns;
}

export const customMoneyRender = ( value : number, className ?: string ) => {
    const validateDecimal = /^\d*\.?\d*$/;
    if ( value && typeof value === 'number' ) {
        value = validateDecimal.test( `${ value }` )
            ? parseInt( value.toFixed( 2 ) ) : value
    }
    return (
        <NumericFormat
            className = { className || 'DefaultStyles' }
            value = { value || 0 }
            thousandSeparator = { true }
            prefix = "$"
            displayType = "text"
        />
    )
}

export const customRenderDate = ( value : string | Date ) => {
    return value ? dayjs( value ).format( 'DD/MM/YYYY - HH:mm:ss' ) : ''
}

export const headers: HeadsInterface[] = [
    {
        label: "Número de Proceso",
        labelValidator: '',
        name: 'Title',
        options: {
            filter: true, sort: true,
            setCellHeaderProps: ( value ) => ({
                className: "titleHeadersTable"
            })
        }
    },
    {
        label: 'Objeto:',
        name: 'Objeto_x003a_',
        options: {
            filter: true, sort: true,
            customBodyRender: ( data: string, tableMeta:any ) => (
                <div>
                    { data.includes( '</div>' ) ? parse( data ) : data }
                </div>
            ),
            setCellHeaderProps: ( value ) => ({
                className: "titleHeadersTable"
            })
        }
    },
    {
        label: 'Presupuesto',
        name: 'Presupuesto',
        options: {
            customBodyRender: customMoneyRender,
            setCellHeaderProps: ( value ) => ({
                className: "titleHeadersTable"
            })
        }
    },
    {
        label: "Fecha del acto de apertura del proceso",
        name: 'Fecha_x0020_del_x0020_acto_x0020',
        options: {
            filter: true, sort: true,
            setCellHeaderProps: ( value ) => {
                return {
                    className: "titleHeadersTable expandColumn" 
                }
            }
        }
    },
    {
        label: "Fecha Inicial Entrega de Propuestas",
        name: 'Fecha_x0020_Final_x0020_Entrega_',
        options: {
            filter: true, sort: true,
            setCellHeaderProps: ( value ) => ({
                className: "titleHeadersTable expandColumn"
            })
        }
    },
    {
        label: "Estado del Proceso",
        name: 'Estado_x0020_del_x0020_Proceso',
        options: {
            filter: true, sort: true,
            setCellHeaderProps: ( value ) => ({
                className: "titleHeadersTable"
            })
        }
    },
    {
        label: "Id",
        name: 'Id',
        options: {
            filter: false, sort: false, display: false,
            setCellHeaderProps: ( value ) => ({
                className: "titleHeadersTable"
            })
        }
    },
];

/**
 * 'Numero de proceso' => Title
 * 'Objeto => Objeto_x003a_
 * 'Presupuesto' => Presupuesto
 * 'Fecha del acto de apertura de proceso' => Fecha_x0020_del_x0020_acto_x0020
 * 'Fecha Inicial Entrega de Propuesta' => Fecha_x0020_Final_x0020_Entrega_
 * 'Estado del proceso' => Estado_x0020_del_x0020_Proceso
 */